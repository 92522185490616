//
export const SOCKS_CONNECT = 'SOCKS_CONNECT';

export const ON_SOCKS_DISCONNECTED = 'ON_SOCKS_DISCONNECTED';
export const ON_SOCKS_CONNECTED = 'ON_SOCKS_CONNECTED';

//События из сокетов
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CONNECTION_STATE = 'CONNECTION_STATE';
export const PLAYLIST = 'PLAYLIST';
export const STATUS = 'STATUS';
export const TREE = 'TREE';
export const STREAM_URL = 'STREAM_URL';
export const OUTPUT = 'OUTPUT';
export const STORED_PLAYLISTS = 'STORED_PLAYLISTS';
export const SONG_TIME = 'SONG_TIME';
export const SEARCH = 'SEARCH';



export const CHANGE_STREAMING = 'CHANGE_STREAMING';
export const CAPTURE_PATH = 'CAPTURE_PATH';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';


//Search
export const SAVE_CONDITION='SAVE_CONDITION';
export const REMOVE_CONDITION='REMOVE_CONDITION';
export const RENAME_CONDITION='RENAME_CONDITION';
export const SHOW_NEW_CONDITION_WINDOW='SHOW_NEW_CONDITION_WINDOW';
export const START_EDIT='START_EDIT';
export const CANCEL_EDIT='CANCEL_EDIT';
export const ADD_SEARCH_BY_NAME='ADD_SEARCH_BY_NAME';
export const SEARCH_NEW='SEARCH_NEW';
export const CLEAR_SEARCH='CLEAR_SEARCH';
